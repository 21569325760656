import styled, { keyframes } from 'styled-components'

const neonAnimation = keyframes`
  from {
    text-shadow:
    0 0 6px rgba(202,228,225,0.92),
    0 0 30px rgba(202,228,225,0.34),
    0 0 12px rgba(30,132,242,0.52),
    0 0 21px rgba(30,132,242,0.92),
    0 0 34px rgba(30,132,242,0.78),
    0 0 54px rgba(30,132,242,0.92);
  }
  to {
    text-shadow:
    0 0 6px rgba(202,228,225,0.98),
    0 0 30px rgba(202,228,225,0.42),
    0 0 12px rgba(30,132,242,0.58),
    0 0 22px rgba(30,132,242,0.84),
    0 0 38px rgba(30,132,242,0.88),
    0 0 60px rgba(30,132,242,1);
  }
`

export const GlowingTextWrapper = styled.div`
  animation: ${neonAnimation} .08s ease-in-out infinite alternate;
  color: #c6e2ff;
`

export const PaddingWrapper = styled.div`
  padding: 5px;
`
export const AlignWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const GlowingBox = styled.div`
  font-size: 15px;
  height: 100%;
  overflow-y: scroll;
  
  box-shadow:
      inset 0 0 50px #fff,      
      inset 20px 0 80px #f0f,   
      inset -20px 0 80px #0ff,  
      inset 20px 0 300px #f0f,  
      inset -20px 0 300px #0ff, 
      0 0 50px #fff,            
      -10px 0 80px #f0f,        
      10px 0 80px #0ff;         

  h1 {
    margin-top: 10px;
  }

  p {
    padding: 5px;
    margin: 10px;
  }
`