import React from "react"
import { PaddingWrapper, GlowingBox, GlowingTextWrapper } from '../styledelements/various'

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const About = ({ location }) => {

  return (
    <Layout path={location}>
      <SEO title="Bio" />
      
      <GlowingBox>
        <Image />
        <PaddingWrapper>
          <GlowingTextWrapper>
            <h1>Hi, i'm Donato Ruy Dattoli!</h1>
            <p>
              I have a long history of different experiences: water polo player, conscientious objector,
              financial and accountant assistant... you just name one... 
            </p>
            <p>
              5 years ago i was tired of burocratic gibberish so i decided to turn my 10 years old coding hobby into 
              a Job.
            </p>
            <p>
              Since then i have learnt new languages, new cultures, a load of different technologies, but most importantly
              i have enjoyed any single project and people. 
            </p>
            <p>
              To me programming has to be like a Peanut Buster Parfait during summertime. Well Javascript is the sweetest cake 
              so far. From Frontend to Backend: React.js, Node.js and all the frameworks and toys in the middle.
            </p>
            <p>
              I will keep coding and plan my next adventure, as long as it's sweet.
            </p>
          </GlowingTextWrapper>
        </PaddingWrapper>
      </GlowingBox>
    </Layout>
  )
}



export default About
